var backend_server_url = 'https://stg2.startdeck.com/backend';
var frontend_url  = 'https://stg2.startdeck.com';

export const environment = {
  production: true,
  vapiKey: 'BM-Q3rUecswquVXm8hmSUX078sg2tACqvvrnY5L1fEgrA_OdRjFRYiEAop8tW8hNphaKGrCE1WrQOfZ-2eq6rcE',
  pusher: {
    key: '8f75fffedd59c91a6e07',
    cluster: 'us2',
  },
  GOOGLE_ANALYTICS_ID: '',
  GOOGLE_TAG_ID:'',
  bugsnag_api_key: '59613b212ee77fd3d8359ad9e7b9f1b6',
  backend_server_url: backend_server_url,
  apiEndpoint: backend_server_url + '/api/',
  frontend_url :frontend_url,
  sharethisPropertyId:'657325aba6ecd400190b3b55'
};